<template>
  <div class="page">
    <div class="page-content analytics-page-content">
      <div class="page-header">
        <div class="page-title">Аналитика</div>
      </div>
      <div class="analytics-head-block">
        <div class="analytics-head-block_select">
          <select name="period" v-model="currentIndex">
            <option
              v-for="(date, index) in dates"
              :key="index"
              :value="index"
            >
              {{
                $moment(date)
                  .locale('ru')
                  .format('MMMM YYYY')
              }}
            </option>
          </select>
          <div class="icon icon-arrow rotate-left"></div>
        </div>
        <div class="analytics-head-block_value">
          Заработано:
          <span class="bold">{{ sumSeparator(analytics.earned[currentIndex] || 0) }}</span>
        </div>
      </div>
      <AnalyticsGrid
        :analytics="analytics"
        :dates="dates"
        @update:monthIndex="updateMonthIndex"
        :monthIndex="currentIndex"
        :loading="loading"
        class="analytics-page-analytic-grid"
      />

      <div class="analytics-funnel">
        <div class="analytics-funnel_title">Средняя воронка</div>
        <div class="analytics-funnel_subtitle">
          Сколько в среднем ушло на закрытие вакансии, и сколько было кандидатов на каждом этапе
        </div>
        <div class="analytics-funnel_items-wrap">
          <div class="analytics-funnel_items">
            <div class="analytics-funnel_item">
              <div class="analytics-funnel_item_title">Входящие</div>
              <div class="analytics-funnel_item_media">{{ currentFunnel[0] }}</div>
            </div>
            <div class="analytics-funnel_item">
              <div class="analytics-funnel_item_title">Cобеседование назначено</div>
              <div class="analytics-funnel_item_media">{{ currentFunnel[1] }}</div>
            </div>
            <div class="analytics-funnel_item">
              <div class="analytics-funnel_item_title">Для заказчика</div>
              <div class="analytics-funnel_item_media">{{ currentFunnel[2] }}</div>
            </div>
            <div class="analytics-funnel_item">
              <div class="analytics-funnel_item_title">Подобран</div>
              <div class="analytics-funnel_item_media">{{ currentFunnel[3] }}</div>
            </div>
          </div>
          <div class="analytics-funnel_vertical-line">
            <img
              class="analytics-funnel_vertical-line_arrow"
              src="@/images/pictures/picture-6.svg"
              alt="funnel arrow"
            >
            <span class="analytics-funnel_vertical-line_value">
              ~ {{ currentCycleTime }}
              {{ declOfNum(currentCycleTime, ['день', 'дня', 'дней']) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsGrid from '@/views/analytics/AnalyticsGrid.vue';

export default {
  name: 'Analytics',
  components: {
    AnalyticsGrid,
  },
  data() {
    return {
      currentIndex: 0,
      today: new Date(),
      analytics: {
        average_cost: [],
        average_cycle_time: [],
        average_funnel: [],
        average_interview: [],
        earned: [],
        interviews: [],
        new_candidates: [],
      },
      loading: false,
    };
  },
  methods: {
    updateMonthIndex(value) {
      this.currentIndex = value;
    },
    load() {
      const date = this.$moment(this.today)
        .format('YYYY-MM');
      this.loading = true;
      this.$server.request2('user/analytics', { date }, (data)=>{
        this.loading = false;
        this.analytics = data.response;
      }, ()=>{
        this.loading = false;
      })
    },
  },
  computed: {
    dates() {
      const arr = new Array(24).fill(null);
      return arr.map((_, index) => this.$moment(this.today)
        .date(1)
        .add(-index, 'months')
        .format('YYYY-MM-DD'));
    },
    currentFunnel() {
      return this.analytics.average_funnel[this.currentIndex] || [];
    },
    currentCycleTime() {
      return this.analytics.average_cycle_time[this.currentIndex];
    },
  },
  mounted() {
    this.load();
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
.page {
  --page-width: 664px;
}

.analytics-page-content {
  overflow-x: hidden;
}

.analytics-head-block {
  margin-bottom: 48px;
  max-width: 664px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_select {
    max-width: 400px;
    position: relative;
    width: 100%;
    margin-right: 28px;
    background: #F4F3F2;
    border-radius: 6px;

    select {
      position: relative;
      width: 100%;
      padding: 13px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      z-index: 1;
      background: transparent;
    }

    .icon {
      top: 50%;
      right: 20px;
      transform: translate(0, -50%) rotate(-90deg);
      position: absolute;
      font-size: 16px;
      margin-left: 8px;
      color: rgba(var(--page-color-main-rgb), .25);
      font-weight: 600;
    }
  }

  &_value {
    flex-shrink: 0;
    white-space: nowrap;
  }
}

.analytics-page-analytic-grid {
  margin-bottom: 64px;
}

.analytics-funnel {
  &_title {
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: -1px;
    color: #111111;
  }

  &_subtitle {
    margin-bottom: 38px;
    font-size: 14px;
    line-height: 20px;
    color: #111111;
    padding-right: 120px;
  }

  &_items-wrap {
    display: flex;
    width: 100%;
  }

  &_vertical-line {
    width: 120px;
    flex-shrink: 0;
    position: relative;

    &_value {
      position: absolute;
      padding: 2px 0;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background: #FFFFFF;
    }

    &_arrow {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
      position: absolute;
    }
  }

  &_items {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_item {
    --funnel-row-max-width: 350px;
    width: 100%;
    display: flex;
    overflow: hidden;

    &_title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F4F3F2;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_media {
      width: var(--funnel-row-width);
      position: relative;
      flex-shrink: 0;
      margin-right: calc(40px + (var(--funnel-row-max-width) / 2 - var(--funnel-row-width) / 2));
      margin-left: 20px;
      height: 67px;
      background: #F4F3F2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;

      &:before, &:after {
        content: "";
        position: absolute;
        width: 40px;
        height: 100px;
        border-radius: 6px;
        background: #F4F3F2;
      }

      &:before {
        left: -15px;
        top: -13px;
        transform: rotate(-30deg);
      }

      &:after {
        right: -15px;
        top: -13px;
        transform: rotate(30deg);
      }
    }

    &:nth-child(1) {
      --funnel-row-width: 350px
    }

    &:nth-child(2) {
      --funnel-row-width: 260px
    }

    &:nth-child(3) {
      --funnel-row-width: 170px
    }

    &:nth-child(4) {
      --funnel-row-width: 80px
    }
  }
}
</style>
